import SystemDashboard from '@/models/SystemDashboard'
import LeadershipDashboardModule from './views/LeadershipDashboardModule'
import OverviewDashboardModule from './views/OverviewDashboardModule'
import WeeklySalesDashboardModule from './views/WeeklySalesDashboardModule'
import SalesManagementDashboardModule from './views/SalesManagementDashboardModule'
import SalesActivityDashboardModule from './views/SalesActivityDashboardModule'
import HamiltonOverviewModule from './views/HamiltonOverviewModule'
import MLBModule from './views/MLBModule'
import ActiveInternationalModule from './views/ActiveInternationalModule'
import CIMWorkloadModule from './views/CIMWorkloadModule'
import SalesDashboardModule from './views/SalesDashboardModule'
import SSLOrderAnalysis from './views/SSLOrderAnalysis'

// const user = getModule(SystemtModule).user

const dashboards: SystemDashboard[] = [
  new LeadershipDashboardModule(),
  new OverviewDashboardModule(),
  new SalesDashboardModule(),
  new SalesManagementDashboardModule(),
  new WeeklySalesDashboardModule(),
  new SalesActivityDashboardModule(),
  new HamiltonOverviewModule(),
  new MLBModule(),
  new MLBModule({
    filters: {
      company_id: '96ed6c23-1824-4aa8-bfb6-10b1c98473c8',
    },
    key: 'directv_dashboard',
    title: 'DirecTV',
    logo: '/images/directv.logo.png',
  }),
  new ActiveInternationalModule(),
  new CIMWorkloadModule(),
  new SSLOrderAnalysis(),
]

export default dashboards
